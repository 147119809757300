const Menu = [
  {
    heading: "menu",
    route: "/association",
    pages: [
      {
        heading: "Entity Organogram",
        route: "/entity-organogram/organogramview",
        fontIcon: "bi-dashboard",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Role Assign",
        route: "/entity-organogram/create-designation",
        fontIcon: "bi-dashboard",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Menu Action Permission",
        route: "/entity-organogram/menu-action-permission",
        fontIcon: "bi-dashboard",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      
    ],
  },
];

export default Menu;
